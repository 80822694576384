import React from 'react'
import Icon2 from '../../static/images/icon2.jpg'
import Icon3 from '../../static/images/icon3.png'
import Icon5 from '../../static/images/icon5.png'

export default function number() {
  return (
    <div>
      <section class="relative pb-0 bg-gray-100 overflow-hidden">
  <div class="pt-40 sm:pt-64 pb-52 bg-black">
    <div class="absolute top-0 left-0 w-full">
      <div class="h-0 mx-10 bg-white"></div>
    </div>
    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap justify-center -mx-4">
        <div class="w-full lg:w-1/3 3xl:w-auto px-4 mb-16 lg:mb-0">
          <div class="max-w-xs px-12 mx-auto">
            <img class="block mb-12" src={Icon2} alt=""/>
            <h3 class="font-heading text-4xl text-white mb-2">89%</h3>
            <h5 class="font-heading text-xl text-white mb-10">Projects is good</h5>
            <p class="text-gray-500">Really, like a sense of truth that comes from within. The final game.</p>
          </div>
        </div>
        <div class="w-full lg:w-1/3 3xl:w-auto px-4 mb-16 lg:mb-0">
          <div class="max-w-xs px-12 mx-auto">
            <img class="block mb-12" src={Icon3} alt=""/>
            <h3 class="font-heading text-4xl text-white mb-2">75%</h3>
            <h5 class="font-heading text-xl text-white mb-10">Projects is perfect!</h5>
            <p class="text-gray-500">Really, like a sense of truth that comes from within. The final game.</p>
          </div>
        </div>
        <div class="w-full lg:w-1/3 3xl:w-auto px-4">
          <div class="max-w-xs px-12 mx-auto">
            <img class="block mb-12" src={Icon5} alt=""/>
            <h3 class="font-heading text-4xl text-white mb-2">14 days</h3>
            <h5 class="font-heading text-xl text-white mb-10">Trial is enough</h5>
            <p class="text-gray-500">Really, like a sense of truth that comes from within. The final game.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="absolute bottom-0 left-1/2">
    <div class="w-px h-18 bg-gray-800"></div>
    <div class="w-px h-26 bg-white"></div>
  </div>
</section>

    </div>
  )
}
