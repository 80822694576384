import React from 'react'
import Pricing from '../components/pricing'
import Number from '../components/number'
import Navbar from '../components/navbar'


export default function solutions() {
  return (
    <div>
      <Navbar/>
        <Number/>
        <Pricing/>
    </div>
  )
}
