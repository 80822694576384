import React from 'react'

export default function pricing() {
  return (
    <div>
      <section class="pt-20 pb-24 2xl:py-40 bg-black">
  <div class="container mx-auto px-4">
    <div class="mb-16 md:mb-24 text-center">
      <h2 class="mb-8 md:mb-14 text-5xl lg:text-6xl 2xl:text-7xl text-white font-bold font-heading">Choose a plan</h2>
      <p class="text-lg text-gray-200">The quick brown fox jumps over the lazy dog.</p>
    </div>
    <div class="max-w-6xl mx-auto">
      <div class="flex flex-wrap items-center -mx-3">
        <div class="w-full lg:w-1/3 px-3 mb-8 lg:mb-0">
          <div class="px-12 py-16 bg-gray-500 rounded-3xl">
            <div class="pb-8 mb-14 border-b border-gray-400">
              <div class="flex justify-between items-center px-3">
                <h3 class="text-4xl text-white font-bold font-heading">Free</h3>
                <p class="text-lg text-white font-bold">$0,00</p>
              </div>
            </div>
            <ul class="text-lg text-white mb-16">
              <li class="flex items-center mb-8">
                <span class="mr-6">
                  <svg width="20" height="16" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="white"></path>
                  </svg>
                </span>
                <span>Complete files</span>
              </li>
              <li class="flex items-center mb-8">
                <span class="mr-6">
                  <svg width="20" height="16" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="white"></path>
                  </svg>
                </span>
                <span>10GB cloud storage</span>
              </li>
              <li class="flex items-center">
                <span class="mr-6">
                  <svg width="20" height="16" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="white"></path>
                  </svg>
                </span>
                <span>5 team members</span>
              </li>
            </ul>
            <div class="text-center"><a class="inline-block px-10 py-4 border border-gray-200 hover:border-gray-100 rounded-full font-bold text-white" href="#">Try free</a></div>
          </div>
        </div>
        <div class="w-full lg:w-1/3 px-3 mb-8 lg:mb-0">
          <div class="px-12 py-16 bg-blue-500 rounded-3xl">
            <div class="pb-8 mb-14 border-b border-blueGray-300">
              <div class="flex justify-between items-center px-3">
                <h3 class="text-4xl text-white font-bold font-heading">Team</h3>
                <p class="text-lg text-white font-bold">$9,99</p>
              </div>
            </div>
            <ul class="text-lg text-white mb-16">
              <li class="flex items-center mb-8">
                <span class="mr-6">
                  <svg width="20" height="16" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="white"></path>
                  </svg>
                </span>
                <span>Complete files</span>
              </li>
              <li class="flex items-center mb-8">
                <span class="mr-6">
                  <svg width="20" height="16" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="white"></path>
                  </svg>
                </span>
                <span>100GB cloud storage</span>
              </li>
              <li class="flex items-center mb-8">
                <span class="mr-6">
                  <svg width="20" height="16" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="white"></path>
                  </svg>
                </span>
                <span>20 team members</span>
              </li>
              <li class="flex items-center mb-8">
                <span class="mr-6">
                  <svg width="20" height="16" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="white"></path>
                  </svg>
                </span>
                <span>Unlimited anonymous board editors</span>
              </li>
              <li class="flex items-center">
                <span class="mr-6">
                  <svg width="20" height="16" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="white"></path>
                  </svg>
                </span>
                <span>10GB cloud storage</span>
              </li>
            </ul>
            <div class="text-center"><a class="inline-block px-10 py-4 border border-blueGray-300 hover:border-blueGray-100 rounded-full font-bold text-white" href="#">Buy now</a></div>
          </div>
        </div>
        <div class="w-full lg:w-1/3 px-3">
          <div class="px-12 py-16 bg-gray-500 rounded-3xl">
            <div class="pb-8 mb-14 border-b border-gray-400">
              <div class="flex justify-between items-center px-3">
                <h3 class="text-4xl text-white font-bold font-heading">Premium</h3>
                <p class="text-lg text-white font-bold">$29,99</p>
              </div>
            </div>
            <ul class="text-lg text-white mb-16">
              <li class="flex items-center mb-8">
                <span class="mr-6">
                  <svg width="20" height="16" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="white"></path>
                  </svg>
                </span>
                <span>Complete files</span>
              </li>
              <li class="flex items-center mb-8">
                <span class="mr-6">
                  <svg width="20" height="16" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="white"></path>
                  </svg>
                </span>
                <span>20GB cloud storage</span>
              </li>
              <li class="flex items-center">
                <span class="mr-6">
                  <svg width="20" height="16" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="white"></path>
                  </svg>
                </span>
                <span>200+ team members</span>
              </li>
            </ul>
            <div class="text-center"><a class="inline-block px-10 py-4 border border-gray-200 hover:border-gray-100 rounded-full font-bold text-white" href="#">Buy now</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}
